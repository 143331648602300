import logoBoanota from '../assets/img/logo-text.svg'
import logoIvtGrade from '../assets/img/logo-ivtgrade.svg'

export const ENV = process.env.REACT_APP_ENV
export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV
export const SCOPE = process.env.REACT_APP_SCOPE
export const IS_SULBRASIL = SCOPE === 'sulbrasil'

// COLORS
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || '#30358b'
export const SECONDARY_COLOR =
  process.env.REACT_APP_SECONDARY_COLOR || '#535ad0'

// AWS S3 ENV
export const SPREAD_BUCKET_NAME = process.env.REACT_APP_SPREAD_BUCKET_NAME

// AWS IDENTITY POOL ENV
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID
export const AWS_IDENTITY_POOL_REGION =
  process.env.REACT_APP_AWS_IDENTITY_POOL_REGION

//  AWS COGNITO ENV
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID
export const AWS_USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
export const AWS_COGNITO_AUTH_DOMAIN =
  process.env.REACT_APP_AWS_COGNITO_AUTH_DOMAIN
export const AWS_COGNITO_SHARED_DOMAIN = process.env.REACT_APP_SHARED_DOMAIN

const resolveLogo = scope => {
  switch (scope.toLowerCase()) {
    case 'ivtgrade':
      return logoIvtGrade
    default:
      return logoBoanota
  }
}

export const LOGO = resolveLogo(SCOPE)

const verifyEnv = () => {
  const envs = {
    ENV,
    BUILD_ENV,
    SCOPE,
    SPREAD_BUCKET_NAME,
    AWS_IDENTITY_POOL_ID,
    AWS_IDENTITY_POOL_REGION,
    AWS_COGNITO_REGION,
    AWS_USER_POOLS_ID,
    AWS_USER_POOLS_WEB_CLIENT_ID,
    AWS_COGNITO_AUTH_DOMAIN,
    AWS_COGNITO_SHARED_DOMAIN
  }

  Object.entries(envs).forEach(([name, value]) => {
    if ([undefined, null, ''].includes(value)) {
      throw new Error(`${name} is not defined, current value is ${value}`)
    }
  })
}

verifyEnv()

const commons = {}

const configs = {
  prod: {
    API_BASE_URL: 'https://z5wruhgk4j.execute-api.us-east-1.amazonaws.com/Prod2'
  },
  staging: {
    API_BASE_URL:
      'https://mfnyv9zq06.execute-api.us-east-1.amazonaws.com/Staging'
  },
  dev: {
    API_BASE_URL: 'https://rruzh3pbjl.execute-api.us-east-1.amazonaws.com/Dev'
  }
}

export const { API_BASE_URL } = { ...commons, ...configs[BUILD_ENV] }

document.documentElement.style.setProperty('--my-primary', PRIMARY_COLOR)
document.documentElement.style.setProperty('--my-secondary', SECONDARY_COLOR)
