import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import camelcaseKeys from 'camelcase-keys'
import { zonedTimeToUtc, format } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'
import shajs from 'sha.js'

const { version } = require('../package.json')

export const VERSION = version

export const hashObject = obj => {
  return shajs('sha256').update(JSON.stringify(obj)).digest('hex')
}

export const logout = () => {
  return Auth.signOut().catch(err => {
    console.error(err)
    toast.error('Erro ao sair', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: 'logout-error'
    })
  })
}

export const getIdToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.idToken.jwtToken
}

export const toCamelCase = value => {
  if (typeof value === 'string') {
    return Object.keys(camelcaseKeys({ [value]: '' }))[0]
  }

  return camelcaseKeys(value, { deep: true })
}

export const factoryDate = (date, timezone = 'America/Sao_Paulo') => {
  if (!timezone) {
    return new Date(date)
  }

  return zonedTimeToUtc(date, timezone)
}

export const formatWithTimezone = (
  date,
  pattern,
  timezone = 'America/Sao_Paulo'
) => {
  return format(date, pattern, { timeZone: timezone, locale: ptBR })
}

export const validCNPJ = cnpj => {
  cnpj =
    cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '') +
    ''
  cnpj = cnpj.replace(/[^\d]+/g, '').trim()

  if (cnpj === '') {
    return false
  }

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(1)) {
    return false
  }

  return true
}
