import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { MdTrendingDown, MdAddCircle } from 'react-icons/md'

import { saveAllData } from '../../lib/save-data'

import Sidebar from './../../components/sidebar'
import PageTitle from './../../components/pageTitle'
import TotalFundos from '../../components/total/fundos'
import Footer from './../../components/footer'
import FundoItem from './../../components/fundo-item'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as FundosActions from '../../redux/store/actions/fundos'

import 'react-toastify/dist/ReactToastify.css'
import './fundos.css'

import { SECONDARY_COLOR } from '../../config/env'

class Fundos extends Component {
  constructor (props) {
    super(props)
    this.props.clickSidebar(5)
  }

  async clickSave () {
    Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    }).then(async result => {
      if (!result.dismiss) {
        await saveAllData(this.props).then(res => {
          toast('Informações salvas no servidor', {
            className: 'toast-background',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress',
            position: 'top-right',
            hideProgressBar: true,
            style: {
              borderRadius: '10px'
            }
          })
        })
        // this.props.history.push('/fundos/' + this.props.match.params.cnpj);
      }
    })
  }

  render () {
    const { fundos, addItem } = this.props

    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title='Fundos & Bancos - Modelos de Endividamento'
              icon={MdTrendingDown}
              btnSalvar={this.clickSave.bind(this)}
              redux_data={fundos}
            />
            <div
              className='bodyContent row justify-content-start no-gutters'
              style={{ paddingTop: '3em' }}
            >
              <div className='col-5'>
                <h4 className='name-item section-item'>Principais Fundos</h4>
                <button
                  className='btn btn-add btn-sm btn-outline-primary'
                  onClick={async () => {
                    const { value: nameFundo } = await Swal.fire({
                      title: 'Adicionar novo fundo',
                      input: 'text',
                      showCancelButton: true,
                      inputPlaceholder: 'digite o nome do fundo',
                      cancelButtonColor: '#525ad0',
                      confirmButtonColor: '#525ad0',
                      cancelButtonText: 'Cancelar',
                      inputValidator: value => {
                        if (!value) {
                          return 'Por favor digite o nome do fundo!'
                        }
                      }
                    })

                    addItem('fundos', nameFundo)
                  }}
                >
                  <MdAddCircle
                    style={{ fontSize: '35px', color: SECONDARY_COLOR }}
                  />
                </button>
                {fundos.fundos.map((fundo, index) => {
                  return (
                    <FundoItem
                      fundo={fundo}
                      key={index}
                      keyItem={index}
                      type='fundos'
                    />
                  )
                })}
              </div>
              <div className='col-1 text-center'>
                <div className='divisor-vertical' />
              </div>
              <div className='col-5'>
                <h4 className='name-item section-item'>Principais Bancos</h4>
                <button
                  className='btn btn-add btn-sm btn-outline-primary'
                  onClick={async () => {
                    const { value: nameFundo } = await Swal.fire({
                      title: 'Adicionar novo banco',
                      input: 'text',
                      showCancelButton: true,
                      inputPlaceholder: 'digite o nome do banco',
                      cancelButtonColor: '#525ad0',
                      confirmButtonColor: '#525ad0',
                      cancelButtonText: 'Cancelar',
                      inputValidator: value => {
                        if (!value) {
                          return 'Por favor digite o nome do banco!'
                        }
                      }
                    })

                    addItem('bancos', nameFundo)
                  }}
                >
                  <MdAddCircle
                    style={{ fontSize: '35px', color: SECONDARY_COLOR }}
                  />
                </button>
                {fundos.bancos.map((fundoBanco, index) => {
                  const indexNew = fundos.fundos.length + (index + 1)

                  return (
                    <FundoItem
                      fundo={fundoBanco}
                      key={indexNew}
                      keyItem={index}
                      type='bancos'
                    />
                  )
                })}
              </div>
            </div>
            <TotalFundos />

            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  fundos: state.fundos,
  parceiros: state.parceiros,
  rating: state.rating,

  sidebar: state.sidebar,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item)),
  addItem: (type, name) => dispatch(FundosActions.addItem(type, name))
})

export default connect(mapStateToProps, mapDispatchToProps)(Fundos)
