import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'

import { MdDataUsage } from 'react-icons/md'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as DadosActions from '../../redux/store/actions/dados_datas'
import {
  setConfiabilidadeDemonstrativoRating,
  setGrauInformalidadeVendasRating,
  setPerfilCarteiraRating
} from '../../redux/store/reducers/rating'

import { ResetSpread } from '../../services/index'

import { saveAllData } from '../../lib/save-data'

import Sidebar from '../../components/sidebar'
import PageTitle from '../../components/pageTitle'
import Footer from '../../components/footer'

const PERFIS_CARTEIRAS = [
  {
    value: 'pulverizada',
    label: 'Pulverizada'
  },
  {
    value: 'mista',
    label: 'Mista'
  },
  {
    value: 'nao_enviada',
    label: 'Não enviada'
  },
  {
    value: 'concentrada',
    label: 'Concentrada'
  }
]

const CONFIABILIDADE_DEMONSTRATIVOS_FINANCEIROS = [
  {
    value: 'auditadas',
    label: 'Auditadas - Big Four (PwC, Deloitte, E&Y e KPMG)'
  },
  {
    value: 'demais',
    label: 'Demais'
  },
  {
    value: 'não auditado com balanços consistentes',
    label: 'Não auditado com balanços consistentes'
  },
  {
    value: 'não auditado com balanços inconsistentes',
    label: 'Não auditado com balanços inconsistentes'
  }
]

export default function RatingPage () {
  const dispatch = useDispatch()
  const allState = useSelector(state => ({
    dados: state.dados,
    ativo: state.ativo,
    passivo: state.passivo,
    dre: state.dre,
    mutacao: state.mutacao,
    fundos: state.fundos,
    parceiros: state.parceiros,
    api: state.api,
    rating: state.rating,
    isGroup: state.sidebar.isGroup
  }))

  useEffect(() => {
    dispatch(SidebarActions.clickSidebar(8))
  }, [])

  const handleReset = async () => {
    const result = await Swal.fire({
      title: 'Resetar Spread ?',
      text: 'Esse spread terá seus dados apagados para que possa reiniciar a inserção. Para confirmar insira a senha master de controle',
      input: 'password',
      inputPlaceholder: 'digite aqui a senha master',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'red',
      icon: 'question'
    })

    if (result.value === 'c8ag565z') {
      Swal.fire({
        title: 'Spread Reset',
        text: 'Por favor aguarde alguns segundos',
        icon: 'info',
        onBeforeOpen: () => {
          Swal.showLoading()
          ResetSpread({
            dados: allState.dados,
            isGroup: allState.isGroup
          }).then(resSuccess => {
            Swal.fire({
              title: 'Spread Reiniciado',
              text: 'Este spread foi reiniciado com sucesso, sua página será atualizada',
              icon: 'info',
              allowOutsideClick: false
            }).then(res => {
              window.location.reload()
            })
          })
        }
      })
    } else {
      Swal.fire(
        'Senha Incorreta',
        'Este SPREAD não foi resetado. Em caso de dúvidas contate o suporte BOA NOTA',
        'error'
      )
    }
  }

  const handleSave = async () => {
    const result = await Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ? As datas inseridas serão bloqueadas para evitar erros nos cálculos',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    })
    try {
      if (!result.dismiss) {
        let error = 0
        if (allState.dados.nome_empresa === '') {
          error++
        }
        if (allState.dados.cnpj === '') {
          error++
        }

        if (error > 0) {
          Swal.fire(
            'Erro no formulário',
            'CNPJ ou nome da empresa não preenchidos na aba de dados',
            'error'
          )
        } else {
          dispatch(DadosActions.lockDates())
          await saveAllData({ ...allState, dispatch }).then(async () => {
            dispatch(DadosActions.saveDataServer())

            toast('Informações salvas no servidor', {
              className: 'toast-background',
              bodyClassName: 'toast-body',
              progressClassName: 'toast-progress',
              position: 'top-right',
              hideProgressBar: true,
              style: {
                borderRadius: '10px'
              }
            })
          })
        }
      }
    } catch (e) {
      toast(
        <div>
          <div>Erro servidor</div>
          <div
            style={{
              borderTop: '1px dashed rgba(255,255,255,1)',
              fontSize: '12px',
              padding: '5px 0',
              marginTop: '5px'
            }}
          >
            Por favor contate o suporte do BOA NOTA.
          </div>
        </div>,
        {
          className: 'toast-red',
          bodyClassName: 'toast-body',
          progressClassName: 'toast-progress',
          position: 'top-right',
          hideProgressBar: true,
          style: {
            borderRadius: '10px',
            background: 'red'
          }
        }
      )
    }
  }

  const renderResetButton = () => {
    return (
      <button
        className='btn btn-danger btn-sm btn-reset'
        style={{ height: '40px' }}
        onClick={() => handleReset()}
      >
        resetar spread
      </button>
    )
  }

  return (
    <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
      <div className='row min-vh-100 no-gutters'>
        <Sidebar />
        <div className='content col-auto'>
          <PageTitle
            title='Rating'
            icon={MdDataUsage}
            btnSalvar={handleSave}
            redux_data={allState.dados}
            btnExternal={renderResetButton()}
          />
          <div className='bodyContent'>
            <form className='form'>
              <div className='form-group row'>
                <div className='col-3'>
                  <label htmlFor='informalidade' className='label'>
                    Grau Informalidade nas Vendas
                  </label>
                </div>
                <div className='col-4'>
                  <div className='input-group'>
                    <input
                      type='number'
                      className='form-control'
                      id='informalidade'
                      value={allState.rating.grauInformalidadeVendas ?? ''}
                      min={0}
                      max={100}
                      onChange={event => {
                        const rawValue = event.target.value
                        const value = parseFloat(rawValue)
                        dispatch(
                          setGrauInformalidadeVendasRating(
                            isNaN(value) ? null : value
                          )
                        )
                      }}
                    />
                    <div className='input-group-append'>
                      <div className='input-group-text'>%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-3'>
                  <label htmlFor='confiabilidade' className='label'>
                    Confiabilidade demonstrativo financeiro
                  </label>
                </div>
                <div className='col-4'>
                  <select
                    id='confiabilidade'
                    onChange={event =>
                      dispatch(
                        setConfiabilidadeDemonstrativoRating(event.target.value)
                      )
                    }
                    value={
                      allState.rating.confiabilidadeDemonstrativosFinanceiros ??
                      ''
                    }
                    className='form-control'
                  >
                    <option value='' disabled>
                      Selecione uma opção
                    </option>
                    {CONFIABILIDADE_DEMONSTRATIVOS_FINANCEIROS.map(item => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-3'>
                  <label htmlFor='perfilCarteira' className='label'>
                    Perfil Carteira
                  </label>
                </div>
                <div className='col-4'>
                  <select
                    id='perfilCarteira'
                    onChange={event =>
                      dispatch(setPerfilCarteiraRating(event.target.value))
                    }
                    value={allState.rating.perfilCarteira ?? ''}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Selecione uma opção
                    </option>
                    {PERFIS_CARTEIRAS.map(item => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
