import React from 'react'

import ProcessingIcon from '../../assets/icons/ProcessingIcon'

export default function Processing () {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primary.main',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
          maxHeight: '90vh',
          width: '75%',
          maxWidth: '600px'
        }}
      >
        <ProcessingIcon height={200} />
        <h5>Estamos processando o seu acesso</h5>
        <span>Aguarde um instante...</span>
      </div>
    </div>
  )
}
