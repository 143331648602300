import { factoryDate } from '../utils'

import JSON_TABLE_DRE from '../assets/table-dre.json'
import JSON_TABLE_ATIVO from '../assets/table-ativo.json'
import JSON_TABLE_PASSIVO from '../assets/table-passivo.json'
import JSON_TABLE_MUTACAO from '../assets/table-mutacao.json'
import JSON_TABLE_FUNDOS from '../assets/table-fundos.json'

export const parseReduxData = data => {
  if (!data || Object.keys(data).length === 0) return null

  // gera cópia do objeto para não reutilizar as referências
  const copy = JSON.parse(JSON.stringify(data || ''))

  return {
    dados: parseDados(copy.dados),
    ativos: parseAtivo(copy.ativos, copy.datas.length),
    passivo: parsePassivo(copy.passivos, copy.datas.length),
    dre: parseDre(copy.dre, copy.datas.length),
    mutacao: parseMutacao(copy.mutacao, copy.datas.length),
    fundos: parseFundos(copy.fundos),
    parceiros: parseParceiros(copy.parceiros ?? []),
    highlights: parseHighlights(copy.highlights),
    rating: parseRating(copy.ratingParameters)
  }
}

const parseAtivo = (ativo, totalDataEl) => {
  const copyJsonTable = JSON.parse(JSON.stringify(JSON_TABLE_ATIVO))

  const state = {
    items: copyJsonTable,
    progress_show_tooltip: false
  }

  const ativoCirculante = [
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.caixa_bancos_aplic_liq_imediata,
        totalDataEl
      ),
      id_back: 'caixa_bancos_aplic_liq_imediata',
      item: 'Caixa, Bancos, Aplic. de Liq. Imediata',
      type: '+',
      id: 0
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.titulo_valores_mobiliarios,
        totalDataEl
      ),
      id_back: 'titulo_valores_mobiliarios',
      item: 'Título de Valores Mobiliários',
      type: '+',
      id: 1
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.contas_receber_clientes_circulante,
        totalDataEl
      ),
      id_back: 'contas_receber_clientes_circulante',
      item: 'Contas a Receber de Clientes',
      type: '+',
      id: 2
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.provisao_devedores_duvidosos,
        totalDataEl
      ),
      id_back: 'provisao_devedores_duvidosos',
      item: '(-)Provisão para Devedores Duvidosos',
      type: '-',
      id: 3
    },
    {
      datas: fillIfsEmpty(ativo.ativo_circulante.estoques, totalDataEl),
      id_back: 'estoques',
      item: 'Estoques',
      type: '+',
      id: 4
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.adiantamento_fornecedores,
        totalDataEl
      ),
      id_back: 'adiantamento_fornecedores',
      item: 'Adiantamento a Fornecedores',
      type: '+',
      id: 5
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.impostos_recuperar,
        totalDataEl
      ),
      id_back: 'impostos_recuperar',
      item: 'Impostos a Recuperar',
      type: '+',
      id: 6
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.partes_relacionadas_circulante,
        totalDataEl
      ),
      id_back: 'partes_relacionadas_circulante',
      item: 'Partes Relacionadas',
      type: '+',
      id: 7
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.outros_ativos_operacionais,
        totalDataEl
      ),
      id_back: 'outros_ativos_operacionais',
      item: 'Outros Ativos Operacionais',
      type: '+',
      id: 8
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.outros_ativos_nao_operacionais,
        totalDataEl
      ),
      id_back: 'outros_ativos_operacionais',
      item: 'Outros Ativos não Operacionais',
      type: '+',
      id: 9
    },
    {
      datas: fillIfsEmpty(
        ativo.ativo_circulante.despesas_pagas_antecipadamente,
        totalDataEl
      ),
      id_back: 'outros_ativos_nao_operacionais',
      item: 'Desp. Pagas Antecipadamente',
      type: '+',
      id: 10
    }
  ]

  const realizavelLongoPrazo = [
    {
      datas: fillIfsEmpty(
        ativo.realizavel_longo_prazo.partes_relacionadas_realizavel_lp,
        totalDataEl
      ),
      id_back: 'partes_relacionadas_realizavel_lp',
      item: 'Partes Relacionadas',
      type: '+',
      id: 11
    },
    {
      datas: fillIfsEmpty(
        ativo.realizavel_longo_prazo.bens_para_venda,
        totalDataEl
      ),
      id_back: 'bens_para_venda',
      item: 'Bens para Venda',
      type: '+',
      id: 12
    },
    {
      datas: fillIfsEmpty(
        ativo.realizavel_longo_prazo.contas_receber_clientes_realizavel_lp,
        totalDataEl
      ),
      id_back: 'contas_receber_clientes_realizavel_lp',
      item: 'Contas a Receber de Clientes',
      type: '+',
      id: 13
    },
    {
      datas: fillIfsEmpty(
        ativo.realizavel_longo_prazo.outras_contas,
        totalDataEl
      ),
      id_back: 'outras_contas',
      item: 'Outras Contas',
      type: '+',
      id: 14
    }
  ]

  const investimentos = [
    {
      datas: fillIfsEmpty(
        ativo.investimentos.particip_contr_coligada,
        totalDataEl
      ),
      id_back: 'particip_contr_coligada',
      item: 'Particip. Contr/Coligada',
      type: '+',
      id: 15
    },
    {
      datas: fillIfsEmpty(
        ativo.investimentos.outros_investimentos,
        totalDataEl
      ),
      id_back: 'outros_investimentos',
      item: 'Outros Investimentos',
      type: '+',
      id: 16
    }
  ]

  const imobilizado = [
    {
      datas: fillIfsEmpty(ativo.imobilizado.imoveis_equipamentos, totalDataEl),
      id_back: 'imoveis_equipamentos',
      item: 'Imóveis/Equipamentos',
      type: '+',
      id: 17
    },
    {
      datas: fillIfsEmpty(
        ativo.imobilizado.imobilizacao_em_andamento,
        totalDataEl
      ),
      id_back: 'imobilizacao_em_andamento',
      item: 'Imobilização em Andamento',
      type: '+',
      id: 18
    },
    {
      datas: fillIfsEmpty(ativo.imobilizado.deprec_amort_exaustao, totalDataEl),
      id_back: 'deprec_amort_exaustao',
      item: '(-) Deprec.  Amort. Exaustão.',
      type: '+',
      id: 19
    }
  ]

  const ativoDiferido = [
    {
      datas: fillIfsEmpty(ativo.ativo_diferido.ativo_diferido, totalDataEl),
      id_back: 'ativo_diferido',
      item: 'Ativo diferido',
      type: '+',
      id: 20
    }
  ]

  const prevState = {
    ...state,
    items: {
      ...state.items,
      ativo_circulante: {
        ...state.items.ativo_circulante,
        items: ativoCirculante,
        total: totalIfEmpty(ativo.ativo_circulante.total, totalDataEl)
      },
      realizavel_longo_prazo: {
        ...state.items.realizavel_longo_prazo,
        items: realizavelLongoPrazo,
        total: totalIfEmpty(ativo.realizavel_longo_prazo.total, totalDataEl)
      },
      investimentos: {
        ...state.items.investimentos,
        items: investimentos,
        total: totalIfEmpty(ativo.investimentos.total, totalDataEl)
      },
      imobilizado: {
        ...state.items.imobilizado,
        items: imobilizado,
        total: totalIfEmpty(ativo.imobilizado.total, totalDataEl)
      },
      ativo_diferido: {
        ...state.items.ativo_diferido,
        items: ativoDiferido,
        total: totalIfEmpty(ativo.ativo_diferido.total, totalDataEl)
      },
      ativo_permanente: {
        ...state.items.ativo_permanente,
        total: totalIfEmpty(ativo.ativo_permanente.total, totalDataEl)
      },
      total: totalIfEmpty(ativo.total, totalDataEl)
    },
    total: totalIfEmpty(ativo.total, totalDataEl)
  }
  return prevState
}

const parseDados = dados => {
  const state = {
    nome_empresa: '',
    cnpj: '',
    grupo: '',
    cidade: '',
    estado: '',
    setor: '',
    fundacao: '',
    moeda: '',
    datas: [],
    gerente: '',
    plataforma: '',
    comentarios: '',
    empresas_consolidadas: [],
    progress_show_tooltip: false,
    show_cidade: false,
    groupExists: null,
    is_saved: false
  }

  return {
    ...state,
    nome_empresa: dados.nome,
    cnpj: dados.cnpj,
    grupo: dados.grupo,
    cidade: dados.cidade,
    estado: dados.estado,
    setor: dados.setor,
    fundacao: dados.data_fundacao ? factoryDate(dados.data_fundacao) : null,
    moeda: dados.moeda,
    datas: dados.datas.map(item => {
      item.data = new Date(item.data.replace('Z', ''))
      return item
    }),
    gerente: dados.gerente,
    plataforma: dados.plataforma,
    comentarios: dados.comentarios,
    groupExists: dados.groupExists,
    empresas_consolidadas: dados.empresas_consolidadas
      ? dados.empresas_consolidadas
      : [],
    is_saved: dados.datas.length > 0
  }
}

const parseDre = (dre, totalDataEl) => {
  const copyJsonTable = JSON.parse(JSON.stringify(JSON_TABLE_DRE))

  const state = {
    items: copyJsonTable,
    progress_show_tooltip: false
  }

  const receitaLiquida = [
    {
      datas: fillIfsEmpty(dre.receita_liquida.receita_bruta, totalDataEl),
      id_back: 'receita_bruta',
      item: 'Receita Bruta',
      type: '+',
      id: 0
    },
    {
      datas: fillIfsEmpty(
        dre.receita_liquida.impostos_incidentes_sobre_vendas,
        totalDataEl
      ),
      id_back: 'impostos_incidentes_sobre_vendas',
      item: 'Imp. Incidentes s/ Vendas',
      type: '+',
      id: 1
    },
    {
      datas: fillIfsEmpty(
        dre.receita_liquida.abatimentos_e_devolucoes,
        totalDataEl
      ),
      id_back: 'abatimentos_e_devolucoes',
      item: 'Abatimentos e Devoluções',
      type: '+',
      id: 2
    }
  ]

  const lucroBruto = [
    {
      datas: fillIfsEmpty(
        dre.lucro_bruto.custo_producao_mercadoria_servicos,
        totalDataEl
      ),
      id_back: 'custo_producao_mercadoria_servicos',
      item: 'Custo Prod./Merc. e Serv.',
      type: '+',
      id: 3
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_bruto.contraprestacoes_leasing,
        totalDataEl
      ),
      id_back: 'contraprestacoes_leasing',
      item: 'Contraprestações Leasing',
      type: '+',
      id: 4
    },
    {
      datas: fillIfsEmpty(dre.lucro_bruto.deprec_amort_exaustao, totalDataEl),
      id_back: 'deprec_amort_exaustao',
      item: 'Deprec./Amort.Exaustão',
      type: '+',
      id: 5
    }
  ]

  const lucroOperacional = [
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional.despesas_administrativas,
        totalDataEl
      ),
      id_back: 'despesas_administrativas',
      item: 'Despesas Administrativas',
      type: '+',
      id: 6
    },
    {
      datas: fillIfsEmpty(dre.lucro_operacional.despesas_vendas, totalDataEl),
      id_back: 'despesas_vendas',
      item: 'Despesas de Vendas',
      type: '+',
      id: 7
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional.despesas_tributarias,
        totalDataEl
      ),
      id_back: 'despesa_tributaria',
      item: 'Despesa Tributária',
      type: '+',
      id: 19
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional.provisão_de_creditos_liqu_duvidosos,
        totalDataEl
      ),
      id_back: 'provisão_de_creditos_liqu_duvidosos',
      item: 'Provisão de Créditos Líqu. Duvidosos',
      type: '+',
      id: 8
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional.outras_despesas_operacionais,
        totalDataEl
      ),
      id_back: 'outras_despesas_operacionais',
      item: 'Outras despesas Operacionais',
      type: '+',
      id: 9
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional.outras_receitas_operacionais,
        totalDataEl
      ),
      id_back: 'outras_receitas_operacionais',
      item: 'Outras Receitas Operacionais',
      type: '+',
      id: 10
    }
  ]

  const lucroOperacionalLiquido = [
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional_liquido.despesas_financeiras,
        totalDataEl
      ),
      id_back: 'despesas_financeiras',
      item: 'Despesas Financeiras',
      type: '+',
      id: 11
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional_liquido.ganho_perdas_monet,
        totalDataEl
      ),
      id_back: 'ganho_perdas_monet',
      item: 'Ganho/ Perdas Monet.',
      type: '+',
      id: 12
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_operacional_liquido.receitas_financeiras,
        totalDataEl
      ),
      id_back: 'receitas_financeiras',
      item: 'Receitas Financeiras',
      type: '+',
      id: 13
    }
  ]

  const lucroLiquido = [
    {
      datas: fillIfsEmpty(
        dre.lucro_liquido.resultado_nao_operacional,
        totalDataEl
      ),
      id_back: 'resultado_nao_operacional',
      item: 'Resultado não Operacional',
      type: '+',
      id: 14
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_liquido.resultado_contr_colig_eq_patrimonial,
        totalDataEl
      ),
      id_back: 'resultado_contr_colig_eq_patrimonial',
      item: 'Resultado Contr/Colig/Eq. Patrimonial',
      type: '+',
      id: 15
    },
    {
      datas: fillIfsEmpty(
        dre.lucro_liquido.participacao_de_adm_empreg,
        totalDataEl
      ),
      id_back: 'participacao_de_adm_empreg',
      item: 'Participação de Adm./Empreg.',
      type: '+',
      id: 16
    },
    {
      datas: fillIfsEmpty(dre.lucro_liquido.provisao_para_ir, totalDataEl),
      id_back: 'provisao_para_ir',
      item: 'Provisão para IR',
      type: '+',
      id: 17
    },
    {
      datas: fillIfsEmpty(dre.lucro_liquido.outros, totalDataEl),
      id_back: 'outros',
      item: 'Outros',
      type: '+',
      id: 18
    }
  ]

  const prevState = {
    ...state,
    items: {
      ...state.items,
      receita_liquida: {
        ...state.items.receita_liquida,
        items: receitaLiquida,
        total: totalIfEmpty(dre.receita_liquida.total, totalDataEl)
      },

      lucro_bruto: {
        ...state.items.lucro_bruto,
        items: lucroBruto,
        total: totalIfEmpty(dre.lucro_bruto.total, totalDataEl)
      },

      lucro_operacional: {
        ...state.items.lucro_operacional,
        items: lucroOperacional,
        total: totalIfEmpty(dre.lucro_operacional.total, totalDataEl)
      },

      lucro_operacional_liquido: {
        ...state.items.lucro_operacional_liquido,
        items: lucroOperacionalLiquido,
        total: totalIfEmpty(dre.lucro_operacional_liquido.total, totalDataEl)
      },

      lucro_liquido: {
        ...state.items.lucro_liquido,
        items: lucroLiquido,
        total: totalIfEmpty(dre.lucro_liquido.total, totalDataEl)
      },

      total: totalIfEmpty(dre.total, totalDataEl)
    },
    total: totalIfEmpty(dre.total, totalDataEl)
  }

  return prevState
}

const parsePassivo = (passivo, totalDataEl) => {
  const copyJsonTable = JSON.parse(JSON.stringify(JSON_TABLE_PASSIVO))

  const state = {
    items: copyJsonTable,
    progress_show_tooltip: false
  }

  const totalBancos = [
    {
      datas: fillIfsEmpty(passivo.total_bancos.fornecedores, totalDataEl),
      id_back: 'fornecedores',
      item: 'Fornecedores',
      type: '+',
      id: 0
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.partes_relacionadas_total_bancos,
        totalDataEl
      ),
      id_back: 'partes_relacionadas_total_bancos',
      item: 'Partes Relacionadas',
      type: '+',
      id: 1
    },
    {
      datas: fillIfsEmpty(passivo.total_bancos.provisao_ir, totalDataEl),
      id_back: 'provisao_ir',
      item: 'Provisão IR/Contribuição Social',
      type: '+',
      id: 2
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.provisoes_sociais_tributarias,
        totalDataEl
      ),
      id_back: 'provisoes_sociais_tributarias',
      item: 'Provisões Sociais e Tributárias',
      type: '+',
      id: 3
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.dividendos_part_adm_empr,
        totalDataEl
      ),
      id_back: 'dividendos_part_adm_empr',
      item: 'Dividendos/Part. Adm./Empr.',
      type: '+',
      id: 4
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.duplicatas_descontadas,
        totalDataEl
      ),
      id_back: 'duplicatas_descontadas',
      item: 'Duplicatas Descontadas',
      type: '+',
      id: 5
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.emprestimos_cp_moeda_nacional,
        totalDataEl
      ),
      id_back: 'emprestimos_cp_moeda_nacional',
      item: 'Emp. de Curto Prazo (nacional)',
      type: '+',
      id: 6
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.emprestimos_cp_moeda_estrangeira,
        totalDataEl
      ),
      id_back: 'emprestimos_cp_moeda_estrangeira',
      item: 'Emp. de Curto Prazo (estrang.)',
      type: '+',
      id: 7
    },
    {
      datas: fillIfsEmpty(passivo.total_bancos.acc_ace, totalDataEl),
      id_back: 'acc_ace',
      item: 'ACC/ACE',
      type: '+',
      id: 8
    },
    {
      datas: fillIfsEmpty(passivo.total_bancos.importacao, totalDataEl),
      id_back: 'importacao',
      item: 'Importação',
      type: '+',
      id: 9
    },
    {
      datas: fillIfsEmpty(
        passivo.total_bancos.parcela_corrente_emprestimo_lp,
        totalDataEl
      ),
      id_back: 'parcela_corrente_emprestimo_lp',
      item: 'Parcela Corrente Empréstimo de LP.',
      type: '+',
      id: 10
    }
  ]

  const passivoCirculante = [
    {
      datas: fillIfsEmpty(
        passivo.passivo_circulante.adiantamento_de_clientes,
        totalDataEl
      ),
      id_back: 'adiantamento_de_clientes',
      item: 'Adiantamento de Clientes',
      type: '+',
      id: 11
    },
    {
      datas: fillIfsEmpty(
        passivo.passivo_circulante.outros_passivos_operacionais,
        totalDataEl
      ),
      id_back: 'outros_passivos_operacionais',
      item: 'Outros Passivos Operacionais',
      type: '+',
      id: 12
    },
    {
      datas: fillIfsEmpty(
        passivo.passivo_circulante.outros_passivos_nao_operacionais,
        totalDataEl
      ),
      id_back: 'outros_passivos_nao_operacionais',
      item: 'Outros Passivos não Operacionais',
      type: '+',
      id: 13
    },
    {
      datas: fillIfsEmpty(
        passivo.passivo_circulante.outras_contas,
        totalDataEl
      ),
      id_back: 'outras_contas',
      item: 'Outras Contas',
      type: '+',
      id: 14
    }
  ]

  const exigivelLongoPrazo = [
    {
      datas: fillIfsEmpty(
        passivo.exigivel_longo_prazo.emprestimo_financiamento_debentures,
        totalDataEl
      ),
      id_back: 'emprestimo_financiamento_debentures',
      item: 'Empréstimo/Financiamento/Debentures',
      type: '+',
      id: 15
    },
    {
      datas: fillIfsEmpty(
        passivo.exigivel_longo_prazo.partes_relacionadas_exigivel_lp,
        totalDataEl
      ),
      id_back: 'partes_relacionadas_exigivel_lp',
      item: 'Partes Relacionadas',
      type: '+',
      id: 16
    },
    {
      datas: fillIfsEmpty(
        passivo.exigivel_longo_prazo.tributos_parcelados,
        totalDataEl
      ),
      id_back: 'tributos_parcelados',
      item: 'Tributos Parcelados',
      type: '+',
      id: 17
    },
    {
      datas: fillIfsEmpty(
        passivo.exigivel_longo_prazo.ir_diferido,
        totalDataEl
      ),
      id_back: 'ir_diferido',
      item: 'Imposto de Renda Diferido',
      type: '+',
      id: 18
    },
    {
      datas: fillIfsEmpty(
        passivo.exigivel_longo_prazo.outras_contas_lp,
        totalDataEl
      ),
      id_back: 'outras_contas_lp',
      item: 'Outras Contas de Longo Prazo',
      type: '+',
      id: 19
    }
  ]

  const resultadoExercicioFuturo = [
    {
      datas: fillIfsEmpty(
        passivo.resultado_exercicio_futuro.resultado_exercicio_futuro,
        totalDataEl
      ),
      id_back: 'resultado_exercicio_futuro',
      item: 'Resultado do exercício futuro',
      type: '+',
      id: 20
    }
  ]

  const patrimonioLiquido = [
    {
      datas: fillIfsEmpty(
        passivo.patrimonio_liquido.interesses_minoritarios,
        totalDataEl
      ),
      id_back: 'interesses_minoritarios',
      item: 'Intereses Minoritários',
      type: '+',
      id: 21
    },
    {
      datas: fillIfsEmpty(
        passivo.patrimonio_liquido.capital_integralizado,
        totalDataEl
      ),
      id_back: 'capital_integralizado',
      item: 'Capital Integralizado',
      type: '+',
      id: 22
    },
    {
      datas: fillIfsEmpty(passivo.patrimonio_liquido.AFAC, totalDataEl),
      id_back: 'AFAC',
      item: 'AFAC',
      type: '+',
      id: 27
    },
    {
      datas: fillIfsEmpty(
        passivo.patrimonio_liquido.reserva_capital,
        totalDataEl
      ),
      id_back: 'reserva_capital',
      item: 'Reserva de Capital',
      type: '+',
      id: 23
    },
    {
      datas: fillIfsEmpty(
        passivo.patrimonio_liquido.reserva_lucros,
        totalDataEl
      ),
      id_back: 'reserva_lucros',
      item: 'Reserva de Lucros',
      type: '+',
      id: 24
    },
    {
      datas: fillIfsEmpty(
        passivo.patrimonio_liquido.lucro_prejuizo_acumulado,
        totalDataEl
      ),
      id_back: 'lucro_prejuizo_acumulado',
      item: 'Lucro/Prej. Acum.',
      type: '+',
      id: 25
    },
    {
      datas: fillIfsEmpty(
        passivo.patrimonio_liquido.reserva_reavaliacao,
        totalDataEl
      ),
      id_back: 'reserva_reavaliacao',
      item: 'Reserva de Reavaliação',
      type: '+',
      id: 26
    }
  ]

  const prevState = {
    ...state,
    items: {
      ...state.items,
      total_bancos: {
        ...state.items.total_bancos,
        items: totalBancos,
        total: totalIfEmpty(passivo.total_bancos.total, totalDataEl)
      },
      passivo_circulante: {
        ...state.items.passivo_circulante,
        items: passivoCirculante,
        total: totalIfEmpty(passivo.passivo_circulante.total, totalDataEl)
      },
      exigivel_longo_prazo: {
        ...state.items.exigivel_longo_prazo,
        items: exigivelLongoPrazo,
        total: totalIfEmpty(passivo.exigivel_longo_prazo.total, totalDataEl)
      },
      resultado_exercicio_futuro: {
        ...state.items.resultado_exercicio_futuro,
        items: resultadoExercicioFuturo,
        total: totalIfEmpty(
          passivo.resultado_exercicio_futuro.total,
          totalDataEl
        )
      },
      patrimonio_liquido: {
        ...state.items.patrimonio_liquido,
        items: patrimonioLiquido,
        total: totalIfEmpty(passivo.patrimonio_liquido.total, totalDataEl)
      },

      exigivel_total: {
        total: totalIfEmpty(passivo.exigivel_total.total, totalDataEl),
        items: []
      },

      ativo_passivo_check: {
        total: totalIfEmpty(passivo.ativo_passivo_check.total, totalDataEl),
        items: []
      },

      total: totalIfEmpty(passivo.total, totalDataEl)
    },
    total: totalIfEmpty(passivo.total, totalDataEl)
  }

  return prevState
}

const parseMutacao = (mutacao, totalDataEl) => {
  const copyJsonTable = JSON.parse(JSON.stringify(JSON_TABLE_MUTACAO))
  const state = {
    items: copyJsonTable,
    progress_show_tooltip: false
  }

  const patrimonioLiquidoFinal = [
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.patrimonio_liquido_inicial,
        totalDataEl
      ),
      id_back: 'patrimonio_liquido_inicial',
      item: 'Patrimônio Líquido Inicial',
      type: '+',
      noedit: true,
      id: 0
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.lucro_liquido_exercicio,
        totalDataEl
      ),
      id_back: 'lucro_liquido_exercicio',
      item: 'Lucro Líquido do Exercício',
      type: '+',
      noedit: true,
      id: 1
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.entrada_de_capital,
        totalDataEl
      ),
      id_back: 'entrada_de_capital',
      item: 'Entrada de Capital',
      type: '+',
      id: 2
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.correcao_monetaria,
        totalDataEl
      ),
      id_back: 'correcao_monetaria',
      item: 'Correção Monetária',
      type: '+',
      id: 3
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.ajuste_exercicios_anteriores,
        totalDataEl
      ),
      id_back: 'ajuste_exercicios_anteriores',
      item: 'Ajuste de Exercícios Anteriores',
      type: '+',
      id: 4
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.incentivos_fiscais_doacoes,
        totalDataEl
      ),
      id_back: 'incentivos_fiscais_doacoes',
      item: 'Incentivos Fiscais/ Doações',
      type: '+',
      id: 5
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.dividendos,
        totalDataEl
      ),
      id_back: 'dividendos',
      item: 'Dividendos',
      type: '+',
      id: 6
    },
    {
      datas: fillIfsEmpty(
        mutacao.patrimonio_liquido_final.reavaliacao_ativos,
        totalDataEl
      ),
      id_back: 'reavaliacao_ativos',
      item: 'Reavaliação de Ativos',
      type: '+',
      id: 7
    }
  ]

  const prevState = {
    ...state,
    items: {
      ...state.items,
      patrimonio_liquido_final: {
        ...state.items.patrimonio_liquido_final,
        items: patrimonioLiquidoFinal,
        total: totalIfEmpty(mutacao.patrimonio_liquido_final.total, totalDataEl)
      },
      dif_patrimonio_liquido: {
        total: totalIfEmpty(mutacao.dif_patrimonio_liquido.total, totalDataEl),
        items: []
      },
      total: totalIfEmpty(mutacao.patrimonio_liquido_final.total, totalDataEl)
    },
    total: totalIfEmpty(mutacao.patrimonio_liquido_final.total, totalDataEl)
  }

  return prevState
}

const parseFundos = fundos => {
  const copyJsonTable = JSON.parse(JSON.stringify(JSON_TABLE_FUNDOS))

  return {
    ...copyJsonTable,
    ...fundos.data
  }
}

const parseParceiros = parceiros => {
  return [...parceiros]
}

const parseHighlights = highlights => {
  const state = {
    highlights: {},
    highlights_corporate: {},
    geracao_caixa_livre: {}
  }

  return {
    ...state,
    ...highlights
  }
}

const parseRating = rating => {
  const state = {
    grauInformalidadeVendas: undefined,
    confiabilidadeDemonstrativosFinanceiros: undefined,
    perfilCarteira: undefined
  }

  return {
    ...state,
    ...rating
  }
}

const fillIfsEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill({ value: 0, percent: '0%' })
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = { value: 0, percent: '0%' }
      }
    }
    return originValue
  }
  return originValue
}

const totalIfEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill(0)
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = 0
      }
    }
    return originValue
  }
  return originValue
}
