import JSON_TABLE from '../../../assets/table-dre.json'

const progress = { ...JSON_TABLE }
Object.keys(progress).forEach(function (item) {
  progress[item] = false
})

delete progress.total

const INITIAL_STATE = {
  items: JSON_TABLE,
  progress,
  progress_show_tooltip: false
}

// FILL WITH {value: 0, percent: 0} if item has a different elements in array
const fillIfsEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill({ value: 0, percent: '0%' })
  if (originValue === undefined || originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = { value: 0, percent: '0%' }
      }
    }
    return originValue
  }
  return originValue
}

// FILL WITH 0 i item has different elements in array
const totalIfEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill(0)
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = 0
      }
    }
    return originValue
  }
  return originValue
}

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'DRE/UPDATE_FROM_REDUX_STYLE') {
    if (action.data) {
      action.data.total = totalIfEmpty(action.data.total, action.total_data_el)
      action.data.items.receita_liquida.total = totalIfEmpty(
        action.data.items.receita_liquida.total,
        action.total_data_el
      )
      action.data.items.lucro_bruto.total = totalIfEmpty(
        action.data.items.lucro_bruto.total,
        action.total_data_el
      )
      action.data.items.lucro_operacional.total = totalIfEmpty(
        action.data.items.lucro_operacional.total,
        action.total_data_el
      )
      action.data.items.lucro_operacional_liquido.total = totalIfEmpty(
        action.data.items.lucro_operacional_liquido.total,
        action.total_data_el
      )
      action.data.items.lucro_liquido.total = totalIfEmpty(
        action.data.items.lucro_liquido.total,
        action.total_data_el
      )
      action.data.items.total = totalIfEmpty(
        action.data.items.total,
        action.total_data_el
      )
    }

    return {
      ...state,
      ...action.data
    }
  }

  if (action.type === 'BOOTSTRAP_DRE') {
    if (action.data) {
      const receitaLiquida = [
        {
          datas: fillIfsEmpty(
            action.data.receita_liquida.receita_bruta,
            action.total_data_el
          ),
          id_back: 'receita_bruta',
          item: 'Receita Bruta',
          type: '+',
          id: 0
        },
        {
          datas: fillIfsEmpty(
            action.data.receita_liquida.impostos_incidentes_sobre_vendas,
            action.total_data_el
          ),
          id_back: 'impostos_incidentes_sobre_vendas',
          item: 'Imp. Incidentes s/ Vendas',
          type: '+',
          id: 1
        },
        {
          datas: fillIfsEmpty(
            action.data.receita_liquida.abatimentos_e_devolucoes,
            action.total_data_el
          ),
          id_back: 'abatimentos_e_devolucoes',
          item: 'Abatimentos e Devoluções',
          type: '+',
          id: 2
        }
      ]

      const lucroBruto = [
        {
          datas: fillIfsEmpty(
            action.data.lucro_bruto.custo_producao_mercadoria_servicos,
            action.total_data_el
          ),
          id_back: 'custo_producao_mercadoria_servicos',
          item: 'Custo Prod./Merc. e Serv.',
          type: '+',
          id: 3
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_bruto.contraprestacoes_leasing,
            action.total_data_el
          ),
          id_back: 'contraprestacoes_leasing',
          item: 'Contraprestações Leasing',
          type: '+',
          id: 4
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_bruto.deprec_amort_exaustao,
            action.total_data_el
          ),
          id_back: 'deprec_amort_exaustao',
          item: 'Deprec./Amort.Exaustão',
          type: '+',
          id: 5
        }
      ]

      const lucroOperacional = [
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional.despesas_administrativas,
            action.total_data_el
          ),
          id_back: 'despesas_administrativas',
          item: 'Despesas Administrativas',
          type: '+',
          id: 6
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional.despesas_vendas,
            action.total_data_el
          ),
          id_back: 'despesas_vendas',
          item: 'Despesas de Vendas',
          type: '+',
          id: 7
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional.despesa_tributaria,
            action.total_data_el
          ),
          id_back: 'despesa_tributaria',
          item: 'Despesa Tributária',
          type: '+',
          id: 19
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional.provisão_de_creditos_liqu_duvidosos,
            action.total_data_el
          ),
          id_back: 'provisão_de_creditos_liqu_duvidosos',
          item: 'Provisão de Créditos Líqu. Duvidosos',
          type: '+',
          id: 8
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional.outras_despesas_operacionais,
            action.total_data_el
          ),
          id_back: 'outras_despesas_operacionais',
          item: 'Outras despesas Operacionais',
          type: '+',
          id: 9
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional.outras_receitas_operacionais,
            action.total_data_el
          ),
          id_back: 'outras_receitas_operacionais',
          item: 'Outras Receitas Operacionais',
          type: '+',
          id: 10
        }
      ]

      const lucroOperacionalLiquido = [
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional_liquido.despesas_financeiras,
            action.total_data_el
          ),
          id_back: 'despesas_financeiras',
          item: 'Despesas Financeiras',
          type: '+',
          id: 11
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional_liquido.ganho_perdas_monet,
            action.total_data_el
          ),
          id_back: 'ganho_perdas_monet',
          item: 'Ganho/ Perdas Monet.',
          type: '+',
          id: 12
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_operacional_liquido.receitas_financeiras,
            action.total_data_el
          ),
          id_back: 'receitas_financeiras',
          item: 'Receitas Financeiras',
          type: '+',
          id: 13
        }
      ]

      const lucroLiquido = [
        {
          datas: fillIfsEmpty(
            action.data.lucro_liquido.resultado_nao_operacional,
            action.total_data_el
          ),
          id_back: 'resultado_nao_operacional',
          item: 'Resultado não Operacional',
          type: '+',
          id: 14
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_liquido.resultado_contr_colig_eq_patrimonial,
            action.total_data_el
          ),
          id_back: 'resultado_contr_colig_eq_patrimonial',
          item: 'Resultado Contr/Colig/Eq. Patrimonial',
          type: '+',
          id: 15
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_liquido.participacao_de_adm_empreg,
            action.total_data_el
          ),
          id_back: 'participacao_de_adm_empreg',
          item: 'Participação de Adm./Empreg.',
          type: '+',
          id: 16
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_liquido.provisao_para_ir,
            action.total_data_el
          ),
          id_back: 'provisao_para_ir',
          item: 'Provisão para IR',
          type: '+',
          id: 17
        },
        {
          datas: fillIfsEmpty(
            action.data.lucro_liquido.outros,
            action.total_data_el
          ),
          id_back: 'outros',
          item: 'Outros',
          type: '+',
          id: 18
        }
      ]

      const prevState = {
        ...state,
        items: {
          ...state.items,
          receita_liquida: {
            ...state.items.receita_liquida,
            items: receitaLiquida,
            total: totalIfEmpty(
              action.data.receita_liquida.total,
              action.total_data_el
            )
          },

          lucro_bruto: {
            ...state.items.lucro_bruto,
            items: lucroBruto,
            total: totalIfEmpty(
              action.data.lucro_bruto.total,
              action.total_data_el
            )
          },

          lucro_operacional: {
            ...state.items.lucro_operacional,
            items: lucroOperacional,
            total: totalIfEmpty(
              action.data.lucro_operacional.total,
              action.total_data_el
            )
          },

          lucro_operacional_liquido: {
            ...state.items.lucro_operacional_liquido,
            items: lucroOperacionalLiquido,
            total: totalIfEmpty(
              action.data.lucro_operacional_liquido.total,
              action.total_data_el
            )
          },

          lucro_liquido: {
            ...state.items.lucro_liquido,
            items: lucroLiquido,
            total: totalIfEmpty(
              action.data.lucro_liquido.total,
              action.total_data_el
            )
          },

          total: totalIfEmpty(action.data.total, action.total_data_el)
        },
        total: totalIfEmpty(action.data.total, action.total_data_el)
      }

      return prevState
    } else {
      const prevState = { ...state }
      prevState.items.receita_liquida.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.receita_liquida.total = Array(action.total_data_el).fill(
        0
      )

      prevState.items.lucro_bruto.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.lucro_bruto.total = Array(action.total_data_el).fill(0)

      prevState.items.lucro_operacional.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.lucro_operacional.total = Array(
        action.total_data_el
      ).fill(0)

      prevState.items.lucro_operacional_liquido.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.lucro_operacional_liquido.total = Array(
        action.total_data_el
      ).fill(0)

      prevState.items.lucro_liquido.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.lucro_liquido.total = Array(action.total_data_el).fill(0)

      prevState.items.total = Array(action.total_data_el).fill(0)
      prevState.total = Array(action.total_data_el).fill(0)

      return prevState
    }
  }

  if (action.type === 'DRE_UPDATE_SIMPLE_DATA') {
    return {
      ...state,
      [action.key]: action.value,
      progress: {
        ...state.progress,
        [action.key]: true
      }
    }
  }

  if (action.type === 'DRE_UPDATE_TABLE_EXCEL') {
    return {
      ...state,
      progress: {
        ...state.progress,
        [action.key_name_items]: true
      },
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas: state.items[action.key_name_items].items[
                  action.key_item
                ].datas.map((data, keyData) =>
                  keyData === action.key_data
                    ? {
                      ...data,
                      value: action.value
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'UPDATE_DATE') {
    const totalDatas = action.value
    const modelItem = { value: 0, percent: 0 }
    const newStateItems = Object.entries(state.items)
    newStateItems.pop()
    const totalArray = new Array(totalDatas).fill(0)
    const totalItem = totalArray.map(item => modelItem)

    const newState = JSON.parse(JSON.stringify(state.items))

    newStateItems.forEach((key, item) => {
      const items = newState[key[0]].items
      const total = totalArray

      items.forEach(item => {
        item.total = totalArray
        total.forEach((totalValue, keyTotal) => {
          item.datas = totalItem
        })
      })

      newState[key[0]].total = totalArray
    })
    return {
      ...state,
      items: newState,
      total: totalArray
    }
  }

  // RECEITA LIQUIDA - SUBSTRACT
  if (action.type === 'RECEITA_LIQUIDA_SUBTRACT') {
    const values = state.items.receita_liquida.items.map(
      item => item.datas[action.key_data].value
    )
    const valuesPorcentage = [...values]
    const firstValue = values.shift()
    const totalSubtract = values.reduce(
      (result, value) => result - value,
      firstValue
    )

    // calculate %
    const porcentage = valuesPorcentage.map(value =>
      (totalSubtract === 0 ? 0 : (value * 100) / totalSubtract).toFixed(0)
    )

    return {
      ...state,
      items: {
        ...state.items,
        receita_liquida: {
          items: state.items.receita_liquida.items.map((item, keyItem) => {
            return {
              ...item,
              datas: state.items.receita_liquida.items[keyItem].datas.map(
                (data, keyData) => {
                  if (keyData === action.key_data) {
                    data.percent = porcentage[keyItem] + '%'
                  }
                  return data
                }
              )
            }
          }),
          total: state.items.receita_liquida.total.map((value, index) =>
            index === action.key_data ? totalSubtract : value
          )
        }
      }
    }
  }

  // LUCRO BRUTO
  if (action.type === 'LUCROBRUTO') {
    const receitaLiquida = state.items.receita_liquida.total[action.key_data]
    const values = state.items.lucro_bruto.items.map(
      item => item.datas[action.key_data].value
    )
    const valuesPorcentage = [...values]

    const totalSubtract = values.reduce(
      (result, value) => result - value,
      receitaLiquida
    )

    // calculate %
    const porcentage = valuesPorcentage.map(value =>
      (totalSubtract === 0 ? 0 : (value * 100) / receitaLiquida).toFixed(0)
    )

    return {
      ...state,
      items: {
        ...state.items,
        lucro_bruto: {
          items: state.items.lucro_bruto.items.map((item, keyItem) => {
            return {
              ...item,
              datas: state.items.lucro_bruto.items[keyItem].datas.map(
                (data, keyData) => {
                  if (keyData === action.key_data) {
                    data.percent = porcentage[keyItem] + '%'
                  }
                  return data
                }
              )
            }
          }),
          total: state.items.lucro_bruto.total.map((value, index) =>
            index === action.key_data ? totalSubtract : value
          )
        }
      }
    }
  }

  // LUCRO OPERACIONAL
  if (action.type === 'LUCROOPERACIONAL') {
    const receitaLiquida = state.items.receita_liquida.total[action.key_data]
    const lucroBruto = state.items.lucro_bruto.total[action.key_data]
    const values = state.items.lucro_operacional.items.map(
      item => item.datas[action.key_data].value
    )
    const valuesPorcentage = [...values]

    const totalSubtract = values.reduce(
      (result, value) => result - value,
      lucroBruto
    )

    // calculate %
    const porcentage = valuesPorcentage.map(value =>
      (totalSubtract === 0 ? 0 : (value * 100) / receitaLiquida).toFixed(0)
    )

    const despesasAdministrativas =
      state.items.lucro_operacional.items[0].datas[action.key_data].value
    const despesasVendas =
      state.items.lucro_operacional.items[1].datas[action.key_data].value
    const provisaoCreditos =
      state.items.lucro_operacional.items[2].datas[action.key_data].value
    const despesasOperacionais =
      state.items.lucro_operacional.items[4].datas[action.key_data].value
    const receitasOperacionais =
      state.items.lucro_operacional.items[5].datas[action.key_data].value
    const despesasTributarias =
      state.items.lucro_operacional.items[3].datas[action.key_data].value

    const totalFinal =
      lucroBruto -
      despesasAdministrativas -
      despesasVendas -
      provisaoCreditos -
      despesasTributarias -
      despesasOperacionais +
      receitasOperacionais

    return {
      ...state,
      items: {
        ...state.items,
        lucro_operacional: {
          items: state.items.lucro_operacional.items.map((item, keyItem) => {
            return {
              ...item,
              datas: state.items.lucro_operacional.items[keyItem].datas.map(
                (data, keyData) => {
                  if (keyData === action.key_data) {
                    data.percent = porcentage[keyItem] + '%'
                  }
                  return data
                }
              )
            }
          }),
          total: state.items.lucro_operacional.total.map((value, index) =>
            index === action.key_data ? totalFinal : value
          )
        }
      }
    }
  }

  // LUCRO OPERACIONAL LIQUIDO
  if (action.type === 'LUCROOPERACIONALLIQUIDO') {
    const receitaLiquida = state.items.receita_liquida.total[action.key_data]
    const lucroOperacional =
      state.items.lucro_operacional.total[action.key_data]
    const receitasFinanceiras =
      state.items.lucro_operacional_liquido.items[2].datas[action.key_data]
        .value
    const values = state.items.lucro_operacional_liquido.items.map(
      item =>
        item.id_back !== 'receitas_financeiras' &&
        item.datas[action.key_data].value
    )
    const valuesPorcentage = [...values]

    const totalSubtract = values.reduce(
      (result, value) => result - value,
      lucroOperacional
    )
    const superTotal = receitasFinanceiras + totalSubtract

    // calculate %
    const porcentage = valuesPorcentage.map(value =>
      (superTotal === 0 ? 0 : (value * 100) / receitaLiquida).toFixed(0)
    )

    return {
      ...state,
      items: {
        ...state.items,
        lucro_operacional_liquido: {
          items: state.items.lucro_operacional_liquido.items.map(
            (item, keyItem) => {
              return {
                ...item,
                datas: state.items.lucro_operacional_liquido.items[
                  keyItem
                ].datas.map((data, keyData) => {
                  if (keyData === action.key_data) {
                    data.percent = porcentage[keyItem] + '%'
                  }
                  return data
                })
              }
            }
          ),
          total: state.items.lucro_operacional_liquido.total.map(
            (value, index) => (index === action.key_data ? superTotal : value)
          )
        }
      }
    }
  }

  // LUCRO LIQUIDO
  if (action.type === 'LUCROLIQUIDO') {
    const receitaLiquida = state.items.receita_liquida.total[action.key_data]
    const lucroOperacionalLiquido =
      state.items.lucro_operacional_liquido.total[action.key_data]
    const values = state.items.lucro_liquido.items.map(
      item => item.datas[action.key_data].value
    )
    const valuesPorcentage = [...values]

    const totalSum =
      values.reduce((result, value) => result + value) + lucroOperacionalLiquido

    // console.log('LUCROLIQUIDO', total_sum, values, lucro_operacional_liquido);

    // calculate %
    const porcentage = valuesPorcentage.map(value =>
      (totalSum === 0 ? 0 : (value * 100) / receitaLiquida).toFixed(0)
    )

    return {
      ...state,
      items: {
        ...state.items,
        lucro_liquido: {
          items: state.items.lucro_liquido.items.map((item, keyItem) => {
            return {
              ...item,
              datas: state.items.lucro_liquido.items[keyItem].datas.map(
                (data, keyData) => {
                  if (keyData === action.key_data) {
                    data.percent = porcentage[keyItem] + '%'
                  }
                  return data
                }
              )
            }
          }),
          total: state.items.lucro_liquido.total.map((value, index) =>
            index === action.key_data ? totalSum : value
          )
        }
      }
    }
  }

  if (action.type === 'SUPER_TOTAL') {
    const lucroOperacionalLiquido = parseFloat(
      state.items.lucro_operacional_liquido.total[action.key_data]
    )
    const resultadoNaoOperacional = parseFloat(
      state.items.lucro_liquido.items[0].datas[action.key_data].value
    )
    const resultadoEqPatrimonial = parseFloat(
      state.items.lucro_liquido.items[1].datas[action.key_data].value
    )
    const participacaoAdm = parseFloat(
      state.items.lucro_liquido.items[2].datas[action.key_data].value
    )
    const provisaoIr = parseFloat(
      state.items.lucro_liquido.items[3].datas[action.key_data].value
    )
    const outros = parseFloat(
      state.items.lucro_liquido.items[4].datas[action.key_data].value
    )

    const total =
      lucroOperacionalLiquido +
      resultadoNaoOperacional +
      resultadoEqPatrimonial +
      participacaoAdm +
      provisaoIr +
      outros

    return {
      ...state,
      items: {
        ...state.items,
        total: state.total.map((totalPrev, key) =>
          key === action.key_data ? total : totalPrev
        )
      },
      total: state.total.map((totalPrev, key) =>
        key === action.key_data ? total : totalPrev
      )
    }
  }

  if (action.type === 'REMOVE_DATA_DRE') {
    const dataIndex = action.value.id

    return {
      ...state,
      items: {
        ...state.items,
        receita_liquida: {
          ...state.items.receita_liquida,
          items: state.items.receita_liquida.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.receita_liquida.total &&
            state.items.receita_liquida.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        lucro_bruto: {
          ...state.items.lucro_bruto,
          items: state.items.lucro_bruto.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.lucro_bruto.total &&
            state.items.lucro_bruto.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        lucro_operacional: {
          ...state.items.lucro_operacional,
          items: state.items.lucro_operacional.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.lucro_operacional.total &&
            state.items.lucro_operacional.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        lucro_operacional_liquido: {
          ...state.items.lucro_operacional_liquido,
          items: state.items.lucro_operacional_liquido.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.lucro_operacional_liquido &&
            state.items.lucro_operacional_liquido.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        lucro_liquido: {
          ...state.items.lucro_liquido,
          items: state.items.lucro_liquido.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.lucro_liquido.total &&
            state.items.lucro_liquido.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        total:
          state.total &&
          state.total.filter((item, index) => index !== dataIndex)
      },
      total:
        state.total && state.total.filter((item, index) => index !== dataIndex)
    }
  }

  if (action.type === 'ADD_NEW_DATE_DRE') {
    const dateBase = { value: 0, percent: 0 }
    const totalBase = 0

    return {
      ...state,
      items: {
        ...state.items,
        receita_liquida: {
          ...state.receita_liquida,
          items: state.items.receita_liquida.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.receita_liquida.total, totalBase]
        },
        lucro_bruto: {
          ...state.lucro_bruto,
          items: state.items.lucro_bruto.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.lucro_bruto.total, totalBase]
        },
        lucro_operacional: {
          ...state.lucro_operacional,
          items: state.items.lucro_operacional.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.lucro_operacional.total, totalBase]
        },
        lucro_operacional_liquido: {
          ...state.lucro_operacional_liquido,
          items: state.items.lucro_operacional_liquido.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.lucro_operacional_liquido.total, totalBase]
        },
        lucro_liquido: {
          ...state.lucro_liquido,
          items: state.items.lucro_liquido.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.lucro_liquido.total, totalBase]
        },
        total: state.total && [...state.total, totalBase]
      },
      total: state.total && [...state.total, totalBase]
    }
  }

  return state
}
